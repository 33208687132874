<template>
  <div class="page">
    <navbar index="4" />

    <!-- banner begain -->    
    <banner>
      <img src="../assets/img/banner_case.jpg" class="img-fluid" alt="诚派科技南京有限责任公司">
    </banner>
    <!-- banner end -->

    <div class="main-content" id="product">

      <!-- 产品标准化案例 begain -->
      <div class="product-box">
        <section-title title="产品标准化案例" subtitle="我们制定了标准化的开发流程，旨在凝聚更多灵感" />
        <div class="product-content">
          <div class="btn-operate prev" @click="turnPage('prev')"><b-icon icon="chevron-left" style="width: 40px; height: 40px;color: #2662FF;"></b-icon></div>
          <div class="btn-operate next" @click="turnPage('next')"><b-icon icon="chevron-right" style="width: 40px; height: 40px;color: #2662FF;"></b-icon></div>
          <div class="content-scroll" :style="proScrollStyle">
            <div class="scroll-item" v-for="(item, index) in list" :key="index">
              <div class="product-item" :class="proIndex == index ? 'active' : ''">
                <div class="product-info">
                  <div class="info-img">
                    <img :src="item.img" class="img-fluid" alt="">
                  </div>
                  <div class="info-txt">
                    <div class="item-no">{{item.no}}</div>
                    <div class="item-name">{{item.name}}</div>
                    <div class="point"><span></span><span></span><span></span></div>
                    <div class="item-desc">{{item.desc}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品标准化案例 end -->

      <!-- 产品标准化案例 begain -->
      <div class="custom-box" id="custom">
        <section-title title="定制化开发服务案例" />
        <div class="container-fluid custom-content">
          <div class="row">
            <div class="col-8 offset-2 content-wrapper">
              <div class="tab-items">
                <div class="item" v-for="(item, index) in caseList" :key="index">
                  <div class="item-wrapper" :class="caseTapIndex == index ? 'active' : ''" :style="caseTapIndex == index ? item.bgcolor_active : ''" @click="changeTabIndex(index)">
                    <div class="tab-icon">
                      <img :src="caseTapIndex == index ? item.icon_active : item.icon" alt="">
                    </div>
                    <div class="tab-name">{{item.type}}</div>
                  </div>
                </div>
              </div>
              <div class="tab-content">
                <div class="tab-wrapper">
                  <div class="content-txt">
                    <div v-for="(item, index) in caseList[caseTapIndex].info" :key="index">
                      <div class="content-item" v-show="caseInfoIndex == index">
                        <h3 class="name">{{item.name}}</h3>
                        <div class="desc">{{item.desc}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="content-imgs">
                    <div class="img">
                      <img :src="caseList[caseTapIndex].info[caseInfoIndex].img" class="img-fluid" alt="">
                    </div>
                    <div class="img-indicator">
                      <span v-for="(item, index) in caseList[caseTapIndex].info" :key="index" :class="caseInfoIndex == index ? 'on' : ''" @click="changeCaseIndex(index)"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品标准化案例 end -->

    </div>
    
    <foot />
  </div>
</template>

<script>
  import navbar from '@/components/navbar'
  import banner from '@/components/banner'
  import foot from '@/components/footer'
  import sectionTitle from '@/components/sectionTitle'
  import {locatePoint} from '@/mixin/locatePoint'
  export default {
    name: 'Case',
    mixins: [locatePoint],
    data () {
      return {
        proIndex: 2,
        caseTapIndex: 0,
        caseInfoIndex: 0,
        proScrollStyle: 'left: -280px',
        proList: [
          {
            no: '01',
            img: require('../assets/img/product_1.jpg'),
            name: '江苏集萃药康生物科技股份有限公司',
            desc: '根据实验用鼠需求，定制详细的繁育计划，维持最合理的繁殖规模，提供符合要求的目的小鼠。在饲养过程中使用专业的小鼠管理软件: eMice生产管理系统，PAD客户端，实时传输小鼠繁殖数据，该系统直接关联销售、运输、财务与客户数据库可以定向展示实时数据信息。'
          },
          {
            no: '02',
            img: require('../assets/img/product_2.jpg'),
            name: '绍兴某新建园区综合能源站',
            desc: '负责园区综合能源站规划，联合投资基金负责综合能源站投资建设与运营，该综合能源站包括分布式光伏、分布式储能、交直流微网混联、负荷分类运行控制、余热利用等单元，通过多能互补优化运行提升能源综合利用效率并降低能源成本。'
          },
          {
            no: '03',
            img: require('../assets/img/product_3.jpg'),
            name: '江苏省人民医院',
            desc: '江苏省人民医院为综合性三级甲等医院。各个楼内系统繁多，管线错综复杂，管理困难。其10号楼建设一套以物联为基础的后勤管理信息系统，将运维信息、资产信息、强电系统关联实时展现于运维人员手机终端。在其1~18楼变电所、配电间应用了可视化综合运维管理系统(VILS)实现了配电系统的后勤维护、强电间配电系统资产信息主干配电线路的上下级关联、运维巡检、员工签到。'
          },
          {
            no: '04',
            img: require('../assets/img/product_4.jpg'),
            name: '南京财经大学',
            desc: '南京财经大学以企业真实场景、真实管理业务为教学情境，遵循企业流程、管理报表、业务单据开发教学内容，以智能感知设备、企业管理软件等为教学工具，以企业岗位工作内容、用人标准为培养路径构建软硬件一体化实践教学平台，为学生提供企业认知、经营感知岗位体验、认知实习等系列解决方案。'
          }
        ],
        caseList: [
          {
            type: '电子商务',
            icon: require('../assets/img/case/case_nav_1.png'),
            icon_active: require('../assets/img/case/case_nav_1_on.png'),
            bgcolor_active: 'background: linear-gradient(90deg, #F88FE4 0%, #779CFB 100%)',
            info: [
              {
                name: '智慧牛城',
                desc: '智慧牛城一是一款集物业管家、 生活管家、汽车管家为一体的软件。可以让业主在衣(清洗、购买)、 食(在线超市、天天特价等)、住(物业服务、装修装饰、家具配饰)、行(汽车管家)都享受到最新、 最全面的优质服务，确认服务、用户评价能直接体现消费者的意见，也便于其他业主用户参考。',
                img: require('../assets/img/case/case_1_1.png')
              },
              {
                name: '鼎玉铉',
                desc: '鼎玉铉一是一 款可以实现卤味线.上购买，配送到家的定制化开发APP。用户可以在线购买几十种不同口味的特色卤味，还拥有秒杀功能，在特定时间可以购买到极为优惠点的商品。购买完成后，有晒单分析入口，点击晒单链接打开分享人购买详情，也可获得优惠。',
                img: require('../assets/img/case/case_1_2.png')
              },
              {
                name: '御品惠',
                desc: '御品惠，是一款拥有厂家端，商家端，会员端三端大型电子商务平台可以使用系统进行订单管理，商品管理，售后管理，库存管理。支持支付宝，微信，银联，现金账户支付。',
                img: require('../assets/img/case/case_1_3.png')
              }
            ]
          },
          {
            type: '教育',
            icon: require('../assets/img/case/case_nav_2.png'),
            icon_active: require('../assets/img/case/case_nav_2_on.png'),
            bgcolor_active: 'background: linear-gradient(90deg, #B1A4E6 0%, #FAA4BA 100%)',
            info: [
              {
                name: '零网智能教育系统局管理员端',
                desc: '零网智能教育系统局管理员端可以添加维护辖内的所有学校，用以审核各校提交的教师信息，分类统计查看各校及教师工作情况，审核各校，各老师的奖罚情况。局机关可以通过此功能向指定的用户发生文件通知。',
                img: require('../assets/img/case/case_2_1.png')
              },
              {
                name: '零网智能教育系统教师端',
                desc: '零网智能教育系统教师端可以向指定的用户发布通知，可以查看学校的总课程表。学生管理，统计查看班级所以学生信息资料，查看学生及家长提出的建议要求并能回复，可统计查看学生的防疫情况。',
                img: require('../assets/img/case/case_2_2.png')
              },
              {
                name: '零网智能教育系统学生端',
                desc: '零网智能教育系统学生端可以查看学生自己的所有科目成绩，个人奖惩情况，查看任课老师信息，可以通过此系统网上报名参加活动和考试，可查看教育局，学校，老师发布的通知。',
                img: require('../assets/img/case/case_2_3.png')
              }
            ]
          },
          {
            type: '医药',
            icon: require('../assets/img/case/case_nav_3.png'),
            icon_active: require('../assets/img/case/case_nav_3_on.png'),
            bgcolor_active: 'background: linear-gradient(90deg, #7185F7 0%, #C381D7 100%)',
            info: [
              {
                name: '东方医院吉安医院',
                desc: '东方医院吉安医院器官捐献系统。用户使用系统可以了解器官捐献须知，然后进行信息登记，然后提交申请。也支持取消申请，信息查询等功能。',
                img: require('../assets/img/case/case_3_1.png')
              },
              {
                name: '常熟市人民医院',
                desc: '常熟市人民医院门诊管理系统，患者可以使用该系统提前预约医师,上面罗列了各种门诊医师的详细信息。',
                img: require('../assets/img/case/case_3_2.png')
              },
              {
                name: '连云港迈克生物',
                desc: '连云港迈克生物药物、药剂管理系统。系统内嵌数十万药品信息,一键扫码完成入库帮助您从繁琐的数据录入中解脱出来.医保刷卡直接下账,药品销售统一管理.药品库存智能化。',
                img: require('../assets/img/case/case_3_3.png')
              }
            ]
          },
          {
            type: '智慧化管理',
            icon: require('../assets/img/case/case_nav_4.png'),
            icon_active: require('../assets/img/case/case_nav_4_on.png'),
            bgcolor_active: 'background: linear-gradient(90deg, #9CC5F9 0%, #539BE5 100%)',
            info: [
              {
                name: '智慧管廊管理系统',
                desc: '智慧管廊移动平台为打造城市地下管廊体系智慧化，实现管网规划、建设、运行、维护及管理、服务的智慧化，保障管网全生命期的安全高效、智能，从而提升城市基础设施功能和城市运行能力。',
                img: require('../assets/img/case/case_4_1.png')
              },
              {
                name: '警犬智能检测系统',
                desc: '警犬智能检测系统能够在无伤害的情况下，实时采集警犬进行运动轨迹行为为数据;并且能够判断警犬是否围绕指定方格内物品，进入指定范围的方格或咬气物品等行为的判断。对突发的外界刺激能够判断警犬受刺激后作出具体反应的所用时间及实时采集警犬的心率变化情况。',
                img: require('../assets/img/case/case_4_2.png')
              },
              {
                name: '智能停车管理系统',
                desc: '智能停车管理系统，可以进行临时车进场确认，收费，解决临时停车计费错误，无法识别车牌等问题，可以紧急开闸关闸，对收费员交接班进行管理，处理地库超时告警。',
                img: require('../assets/img/case/case_4_3.png')
              }
            ]
          },
          {
            type: '农业',
            icon: require('../assets/img/case/case_nav_5.png'),
            icon_active: require('../assets/img/case/case_nav_5_on.png'),
            bgcolor_active: 'background: linear-gradient(90deg, #3BE7C4 0%, #65CDF5 100%)',
            info: [
              {
                name: '多吃菜',
                desc: '多吃菜是一款支持用户在线下单购买农产品的购物APP。用户可以根据需要切换门店购买。用户可以使用这款APP买到离自己最近新鲜又便宜的蔬菜。',
                img: require('../assets/img/case/case_5_1.png')
              }
            ]
          },
        ],
      }
    },
    computed: {
      list () {
        let banners = [...this.proList]
        banners = [this.proList[this.proList.length -1], ...banners]
        banners = [...banners, this.proList[0]]
        return banners
      }
    },
    created () {
      if (this.$route.query.caseTapIndex) {
        this.caseTapIndex = this.$route.query.caseTapIndex
      }
      if (this.$route.query.caseInfoIndex) {
        this.caseInfoIndex = this.$route.query.caseInfoIndex
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.LP();
      })
    },
    methods: {
      turnPage (direction) {
        if (direction == 'next') {
          if (this.proIndex + 1 <= (this.list.length - 2)) {
            this.proIndex = this.proIndex + 1
          } else {
            this.proIndex = 1
          }
        }
        if (direction == 'prev') {
          if (this.proIndex - 1 <= 0) {
            this.proIndex = this.list.length - 2
          } else {
            this.proIndex = this.proIndex - 1
          }
        }

        this.proScrollStyle = 'left: -' + ((this.proIndex - 1) * 280) + 'px'
      },
      changeTabIndex (index) {
        this.caseTapIndex = index
        this.caseInfoIndex = 0
      },
      changeCaseIndex (index) {
        this.caseInfoIndex = index
      }
    },
    components: {
      navbar,
      banner,
      sectionTitle,
      foot
    }
  }
</script>

<style lang="scss" scoped>
  .main-content {
    .product-box {
      margin: 0 auto;
      padding: 60px 0;
      max-width: 1300px;
      overflow: scroll-x;
      .product-content {
        margin-top: 60px;
        width: 100%;
        height: 590px;
        position: relative;
        overflow: hidden;
        .btn-operate {
          position: absolute;
          left: 224px;
          top: 50%;
          transform: translateY(-50%);
          padding-top: 20px;
          width: 80px;
          height: 80px;
          border-radius: 40px;
          box-shadow: 0 8px 21px 0 rgba(38, 98, 255, 0.22);
          background-color: #fff;
          overflow: hidden;
          z-index: 9;
          text-align: center;
          opacity: .4;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          &.next {
            left: auto;
            right: 224px;
          }
        }
        .content-scroll {
          padding-top: 44px;
          position: absolute;
          z-index: 5;
          display: flex;
          flex-wrap: nowrap;
          width: 1670px;
          height: 100%;
          left:  0;
          top: 0;
        }
        .scroll-item {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
        .product-item {
          width: 270px;
          height: 500px;
          padding: 45px 30px;
          box-sizing: border-box;
          background-color: #F1F3F8;
          transition: all .3 ease-in;
          cursor: pointer;
          .product-info {
            .info-img {
              display: none;
            }
            .info-txt{
              position: relative;
              .item-no {
                margin-bottom: 34px;
                line-height: 1;
                font-size: 100px;
                font-weight: bold;
                color: #DADCE5;
              }
              .item-name {
                margin-bottom: 30px;
                line-height: 1;
                font-size: 26px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #333;
              }
              .point {
                margin-bottom: 30px;
                height: 7px;
                font-size: 0;
                span {
                  margin-right: 14px;
                  display: inline-block;
                  width: 7px;
                  height: 7px;
                  background-color: #FECF59;
                  &:last-child {
                    margin: 0;
                  }
                }
              }
              .item-desc {
                line-height: 1.4;
                font-size: 20px;
                color: #999;
                overflow: hidden;
                -webkit-line-clamp: 7;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
          }
          &.active {
            // width: 860px;
            width: 740px;
            // height: 546px;
            height: 560px;
            background-color: #4164EA;
            .product-info {
              width: 100%;
              // height: 495px;
              height: 500px;
              display: flex;
              justify-content: space-between;
              .info-img {
                display: block;
                // float: left;
                flex: 0 0 340px;
                width: 340px; 
                height: 100%;
                transform: translateY(-65px);
                img {
                  width: 100%;
                  height: 100%;
                }       
              }
              .info-txt {
                margin-left: 20px;
                // float: right;
                // width: 350px;
                width: 300px;
                height: 100%;
                .item-no {
                  color: rgba(255, 255, 255, .2);
                }
                .point {
                  display: none;
                }
                .item-name {
                  position: absolute;
                  top: 40px;
                  white-space: initial;
                  color: #fff;
                }
                .item-desc {
                  width: 100%;
                  height: 360px;
                  color: #fff;
                  -webkit-line-clamp: 10;
                }
              } 
            }
          }
        }
        
      }
    }
    .custom-box {
      margin: 0 auto;
      padding: 60px 0;
      .custom-content {
        margin-top: 60px;
        .content-wrapper {
          display: flex;
          margin: 0 auto;
          padding: 0;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, .4);
        }
        .tab-items {
          flex: 0 0 300px;
          .item {
            border-left: 0;
            cursor: pointer;
            .item-wrapper {
              display: flex;
              align-items: center;
              padding: 30px;
              border-right: 1px solid #E2E3E4;
              border-bottom: 1px solid #E2E3E4;
              .tab-icon {
                width: 70px;
                height: 70px;
                img {
                  width: 70px;
                  height: 70px;
                }
              }
              .tab-name {
                margin-left: 30px;
                font-size: 26px;
                color: #666;
              }
              &.active {
                .tab-name {
                  color: #fff;
                }
              }
            }
            &:last-child {
              .item-wrapper {
                border-bottom: 0;
              }
            }
          }
        }
        .tab-content {
          flex: 1;
          display: flex;
          padding: 40px;
          .tab-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            .content-txt {
              .content-item {
                height: 100%;
                display: flex;
                flex-direction: column;
                .name {
                  margin-bottom: 20px;
                  font-size: 28px;
                  color: #333;
                }
                .desc {
                  max-height: 200px;
                  line-height: 2;
                  font-size: 20px;
                  color: #999;
                  overflow-y: scroll;
                }
              }
            }
            .content-imgs {
              margin-right: 50px;
              flex: 0 0 300px;
              text-align: center;
              .img {
                height: 260px;
                img {
                  height: 100%;
                }
              }
              .img-indicator {
                margin-top: 10px;
                padding: 10px 0;
                span {
                  position: relative;
                  display: inline-block;
                  margin: 0 15px;
                  width: 40px;
                  height: 10px;
                  border-radius: 4px;
                  background-color: #F2F2F2;
                  cursor: pointer;
                  &::after, &::before {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 0;
                    width: 100%;
                    height: 10px;
                  }
                   &::before {
                     top: auto;
                     bottom: -10px;
                   }
                  &.on {
                    background-color: #C8CEEA;
                  }
                }
              }
            }  
          }
        }
      } 
    }
  }
</style>